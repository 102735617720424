<template>
  <div>
    <suy-table v-model:columns="columns">
      <template #search>
        <a-form layout="inline" :model="state">
          <a-form-item label="">
            <a-input v-model:value="customerName" placeholder="客户名称" />
          </a-form-item>
          <a-form-item label="">
            <a-cascader :options="$store.state.app.cityDict" @change="destination" :changeOnSelect="true" placeholder="始发地" />
          </a-form-item>
          <a-form-item label="">
            <a-cascader :options="$store.state.app.cityDict" @change="destination" :changeOnSelect="true" placeholder="目的地" />
          </a-form-item>
          <a-form-item>
            <a-button type="primary" @click="searchBtn" :loading="loading">搜索 </a-button>
          </a-form-item>
        </a-form>
      </template>
      <!-- 表格主体 -->
      <a-table :columns="columns" :row-key="record => record.id" :data-source="data" :bordered="false" :pagination="pagination" :loading="loading" @change="handleTableChange">
        <template #carType="{ record }">
          <div>
            <span>
              {{record.brand + record.model}}
            </span>
          </div>
        </template>
        <template #operation="{ record }">
          <div>
            <span>
              <a @click="edit(record)">详情</a>
            </span>
          </div>
        </template>
      </a-table>
    </suy-table>
    <a-drawer title="查看或编辑信息" width="95%" :visible="visible" :body-style="{ paddingBottom: '80px' }" @close="close" destroyOnClose>
      <a-table :columns="columnsDetail" :row-key="record => record.vinNo" :data-source="dataDetail" :bordered="false" :pagination="paginationDetail" :loading="loadingDetail" @change="DetailChange">
        <template #takeOutFee="{ record }">
          <div>
            <span>
              <span v-show="!record.takeOutFeeStatus">{{record.takeOutFee}}</span>
              <a-input v-show="record.takeOutFeeStatus" v-model:value="record.takeOutFee" style="width:50px" />
              <a v-if="inquiryState.value !== 2" @click="record.takeOutFeeStatus = !record.takeOutFeeStatus">{{!record.takeOutFeeStatus ? ' 编辑' : ' 确认'}}</a>
            </span>
          </div>
        </template>

        <template #placeInFee="{ record }">
          <div>
            <span>
              <span v-show="!record.placeInFeeStatus">{{record.placeInFee}}</span>
              <a-input v-show="record.placeInFeeStatus" v-model:value="record.placeInFee" style="width:50px" />
              <a v-if="inquiryState.value !== 2" @click="record.placeInFeeStatus = !record.placeInFeeStatus">{{!record.placeInFeeStatus ? ' 编辑' : ' 确认'}}</a>
            </span>
          </div>
        </template>

        <template #freight="{ record }">
          <div>
            <span>
              <span v-show="!record.freightStatus">{{record.freight}} </span>
              <a-input v-show="record.freightStatus" v-model:value="record.freight" style="width:50px" />
              <a v-if="inquiryState.value !== 2" @click="record.freightStatus = !record.freightStatus">{{!record.freightStatus ? ' 编辑' : ' 确认'}}</a>
            </span>
          </div>
        </template>

        <template #remark="{ record }">
          <div>
            <span>
              <span v-show="!record.remarkStatus">{{record.remark}}</span>
              <a-textarea v-show="record.remarkStatus" v-model:value="record.remark" />
              <div v-if="inquiryState.value !== 2"><a @click="record.remarkStatus = !record.remarkStatus">{{!record.remarkStatus ? ' 添加备注' : ' 确认'}}</a></div>
            </span>
          </div>
        </template>

        <template #operation="{ record }">
          <div v-show="editStatus">
            <a @click="detailConfirm(record)">提交</a>
          </div>
        </template>
      </a-table>
      <a-form :model="state" :label-col="4" :wrapper-col="14">
        <a-form-item label="当前状态">
          <span style="color:#f00">{{inquiryState.label}}</span>
        </a-form-item>
        <a-form-item label="状态编辑" v-if="inquiryState.value !== 2">
          <a-radio-group :options="inquiryStatus" :v-model:value="label" @change="changeStatus" />
        </a-form-item>
        <a-form-item label="总时效">
          <a-input v-if="inquiryState.value !== 2" v-model:value="totalData.time" style="width:200px" />
          <span v-show="inquiryState.value === 2">{{totalData.time}}</span>
        </a-form-item>
        <a-form-item label="总路程">
          <a-input v-if="inquiryState.value !== 2" v-model:value="totalData.mileage" style="width:200px" />
          <span v-show="inquiryState.value === 2">{{totalData.mileage}}</span>
        </a-form-item>
        <a-form-item label="备注信息">
          <a-textarea v-if="inquiryState.value !== 2" v-model:value="totalData.remark" style="width:200px" />
          <span v-show="inquiryState.value === 2">{{totalData.remark}}</span>
        </a-form-item>
      </a-form>
      <a-button v-if="inquiryState.value !== 2" type="primary" style="margin-right: 8px" @click="confirm">确认</a-button>
      <a-button v-if="inquiryState.value !== 2" @click="close">取消</a-button>
      <div :style="{
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        padding: '10px 16px',
        background: '#fff',
        textAlign: 'right',
        zIndex: 1,
      }">
      </div>
    </a-drawer>
  </div>
</template>

<script>
import { message } from 'ant-design-vue'
import { onMounted, reactive, toRefs } from 'vue'
// import { FormOutlined } from '@ant-design/icons-vue'
import SuyTable from '@/components/SuyTable'
import { PrefixZero } from '@/utils/util'
import { page, detail, editData, editDetail } from '@/api/inquiry/inquiryList'
export default {
  components: {
    SuyTable
    // FormOutlined
  },
  setup () {
    const state = reactive({
      editStatus: false,
      visible: false,
      loading: false,
      loadingDetail: false,
      editShow: true,
      mileageShow: false,
      freightShow: false,
      timeShow: false,
      takeOutFeeStatus: false,
      customerName: '',
      inquiryId: '',
      num: null,
      totalData: {
        id: '',
        mileage: '',
        remark: '',
        time: ''
      },
      data: [],
      dataDetail: [],
      inquiryState: {},
      inquiryStatus: [
        {
          label: '处理中',
          value: 1
        },
        {
          label: '完成',
          value: 2
        }
      ],
      columns: [
        {
          title: '编号',
          dataIndex: 'number',
          // width: '7%',
          slots: {
            customRender: 'number'
          }
        },
        {
          title: '始发地',
          dataIndex: 'startDetails',
          // width: '18%',
          slots: {
            customRender: 'startDetails'
          }
        },
        {
          title: '目的地',
          dataIndex: 'endDetails',
          // width: '18%',
          slots: {
            customRender: 'endDetails'
          }
        },
        {
          title: '预计开始时间',
          dataIndex: 'createTime',
          slots: {
            customRender: 'createTime'
          }
        },
        {
          title: '询价时间',
          dataIndex: 'subscribeTime',
          // width: '6%',
          slots: {
            customRender: 'subscribeTime'
          }
        },
        {
          title: '数量',
          dataIndex: 'carCount',
          slots: {
            customRender: 'carCount'
          }
        },
        {
          title: '报价状态',
          dataIndex: 'inquiryStatus.label',
          slots: {
            customRender: 'inquiryStatus.label'
          }
        },
        {
          title: '操作',
          dataIndex: 'operation',
          slots: {
            customRender: 'operation'
          }
        }
      ],
      columnsDetail: [
        {
          title: '车牌号',
          dataIndex: 'vinNo',
          width: '5%'
        },
        {
          title: '车辆类型',
          dataIndex: 'model',
          width: '10%',
          slots: {
            customRender: 'carType'
          }
        },
        {
          title: '送车地址',
          dataIndex: 'takeOutAddress',
          width: '20%',
        },
        {
          title: '送车价格',
          dataIndex: 'takeOutFee',
          width: '10%',
          slots: {
            customRender: 'takeOutFee'
          }
        },
        {
          title: '取车地址',
          dataIndex: 'placeInAddress',
          width: '20%',
        },
        {
          title: '取车价格',
          dataIndex: 'placeInFee',
          width: '10%',
          slots: {
            customRender: 'placeInFee'
          }
        },
        {
          title: '运送费用',
          dataIndex: 'freight',
          width: '10%',
          slots: {
            customRender: 'freight'
          }
        },
        {
          title: '备注',
          dataIndex: 'remark',
          width: '10%',
          slots: {
            customRender: 'remark'
          }
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '10%',
          slots: {
            customRender: 'operation'
          }
        }
      ],
      pagination: {
        current: 1,
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      paginationDetail: {
        current: 1,
        pageSize: 4,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['4', '10'],
        showTotal: (total, range) => `共 ${total} 条`
      }
    })
    onMounted(() => {
      loadData()
    })

    const loadData = () => {
      state.loading = true
      page({
        customerName: state.customerName,
        current: state.pagination.current,
        size: state.pagination.size
      }).then(res => {
        if (res.code === 10000) {
          state.data = res.data.records
          state.data.forEach((item, index) => {
            item.number = PrefixZero(index + 1, 3)
          })
          // console.log(res, state.data)
          state.pagination.total = res.data.total
          state.pagination.current = res.data.current
          state.pagination.pageSize = res.data.size
          state.loading = false
        }
        // console.log(res)
      }).catch(err => console.log(err))
    }
    const searchBtn = () => {
      loadData()
    }
    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }
    const destination = val => {
      console.log(val)
      // state.render.startAddress.province = val[0]
      // state.render.startAddress.city = val[1]
      // state.render.startAddress.area = val[2]
    }
    const edit = record => {
      state.inquiryId = record.id
      state.loadingDetail = true
      detail(state.inquiryId)
        .then(res => {
          if (res.code === 10000) {
            state.visible = true
            state.totalData = res.data
            state.inquiryState = res.data.inquiryStatus
            if (state.totalData.inquiryStatus.value !== 2) {
              state.editStatus = true
            }else{
              state.editStatus = false
            }
            if (state.totalData.inquiryStatus.value === 1) {
              state.inquiryStatus = [{ label: '完成', value: 2 }]
            } else {
              state.inquiryStatus = [{
                label: '处理中',
                value: 1
              },
              {
                label: '完成',
                value: 2
              }]
            }
            state.dataDetail = res.data.inquiryVehicles
            state.dataDetail.forEach(item => {
              item.takeOutFeeStatus = false
              item.placeInFeeStatus = false
              item.remarkStatus = false
              item.freightStatus = false
            })
            state.loadingDetail = false
            // console.log(state.detail)
          }
        })
        .catch(err => console.log(err))
    }

    const DetailChange = pag => {
      state.paginationDetail.current = pag.current
      state.paginationDetail.pageSize = pag.pageSize
    }

    const detailConfirm = record => {
      editDetail(record)
        .then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
          }
        }).catch(err => console.log(err))
    }
    const confirm = () => {
      state.visible = false
      editData({
        id: state.inquiryId,
        inquiryStatus: state.totalData.inquiryStatus,
        inquiryVehicles: state.dataDetail,
        mileage: state.totalData.mileage,
        remark: state.totalData.remark,
        time: state.totalData.time
      }).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          loadData()
        }
      }).catch(err => console.log(err))
    }
    const changeStatus = (e) => {
      if (e.target.value === 1) state.totalData.inquiryStatus = { value: 1, label: '处理中' }
      else state.totalData.inquiryStatus = { value: 2, label: '完成' }
    }
    const close = () => {
      state.visible = false
    }

    return {
      ...toRefs(state),
      handleTableChange,
      detailConfirm,
      changeStatus,
      DetailChange,
      destination,
      searchBtn,
      confirm,
      close,
      edit
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
