import {
  postBodyRequest,
  getRequest
} from '@/utils/axios'

export function page (params) {
  return postBodyRequest('/trans/inquiry/page', params)
}

export function detail (inquiryId, params) {
  return getRequest(`/trans/ymc/inquiry/${inquiryId}`, params)
}

export function editData (params) {
  return postBodyRequest('/trans/inquiry/edit', params)
}

export function editDetail (params) {
  return postBodyRequest('/trans/inquiry/edit/vehicle', params)
}
